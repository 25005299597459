.article-card {
  width: 35rem !important;
  margin: 25px;
  background-color: #242424 !important;
  padding: 15px;
}

.article-card-hover {
  -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.1s ease-in-out;
    transition: 0.1s ease-in-out;
    &:hover {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }
}

.article-card h2 {
  margin: 0px !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.article-card img {
  height: 7rem;
  width: auto;
  margin-bottom: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.article-card p {
  text-align: justify;
}

.article-card-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.article-card caption {
  float: left;
}

.article-card a {
  float: right;
}

.card-footer {
  border-top: var(--bs-card-border-width) solid rgb(100, 100, 100) !important;
}

.article-card-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
}