.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.home h1 {
  text-align: center;
  margin: 0;
  font-size: 10vh;
  color: white;
}

.home img {
  width: 15rem;
  height: auto;
}