.article, .article-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1 {
  text-align: center;
  margin: 25px !important;
}

.article-info p {
  color: rgb(150, 150, 150);
  font-weight: bold;
  margin: 0;
  margin-top: 10px;
  text-align: justify;
}

.article-info-col-1 {
  min-width: 175px;
}

.article-info-col-1 p {
  text-align: right;
}

.article-info-col-2 {
  min-width: 175px;
}

.article-info-col-21 p {
  text-align: left;
}

.article-body {
  max-width: 1100px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.article-body-intro {
  font-weight: bold;
}

.article-body p, h2, h3, h4, h5, h6, ul {
  text-align: justify;
  width: 100%;
}

.article-body h3, h4, h5, h6 {
  font-style: italic;
}

.article-body p {
  margin: 0;
  margin-top: 15px;
  margin-bottom: 15px;
}

.article-body img {
  border-radius: 25px;
  border: 3px #29a7f1 solid;
  width: 90% !important;
  margin-top: 20px;
}

.article-image caption {
  width: 100%;
  text-align: center;
  color: rgb(150, 150, 150);
  margin-bottom: 20px
}

.article-body code {
  color: white;
  background-color: #242424;
  font-style: italic;
}

.article-note {
  margin: 25px;
  background-color: #242424 !important;
  padding: 25px;
  font-style: italic;
  text-align: justify;
  width: 90%;
}

.article-prerequisites {
  width: 100% !important;
  text-align: justify;
}

.article-prerequisites p {
  margin: 0;
}