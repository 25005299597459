.blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.blog h1 {
  text-align: center;
}

.blog-article-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}