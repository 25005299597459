.footer {
  background-color: #242424;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.footer p {
  margin: 0px;
  text-align: center;
}

.footer-love {
  color: #29a7f1;
}