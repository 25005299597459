.app {
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}

.divider {
  background-color: white !important;
  width: 75% !important;
  margin: 25px !important;
}

.vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.article-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}