.nav-bar { 
	background-color: #242424;
	padding-left: 20px !important;
	padding-right: 20px !important;
}

.nav-bar-logo {
	text-align: center;
	color: white !important;
}

.nav-bar img {
	margin-right: 25px;
}

.nav-bar-toggle {
	border-color: #ffffff !important;
}

.navbar-toggler {
	color: #ffffff !important;
}

.nav-bar a {
	color: #ffffff !important;
	margin-left: 20px !important;
}

.nav-bar-links {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}